<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            เบิก
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-date v-model="Widen.WidenDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="จำนวนเงิน"
                  placeholder="จำนวนเงิน"
                  disabled
                  dense
                  v-model="Widen.WidenTotal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  dense
                  v-model="Widen.WidenRemark"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1">
                <v-btn color="primary" width="100%" @click="Insert()">
                  <v-icon>fa fa-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            บิลที่ค้างชำระ
          </v-card-title>
          <v-data-table
            :items-per-page="5"
            :headers="headers"
            :items="desserts.Array"
            class="elevation-1"
          >
            <template v-slot:item.action="{ item }">
              <v-btn class="mx-2 red" fab dark small @click="Delete(item)">
                <v-icon>fa fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Widen: {
        UserId: this.$route.query.UserId,
        WidenTotal: "",
        WidenRemark: "",
        WidenDate: "",
      },
      UserId: this.$route.query.UserId,
      desserts: [],
      headers: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "BillSalesDate",
        },
        {
          text: "แปลง",
          align: "left",
          sortable: true,
          value: "Rubber.RubberName",
        },
        {
          text: "น้ำหนัก - ตระกล้า",
          align: "left",
          sortable: true,
          value: "RubberWeight",
        },
        {
          text: "น้ำหนักรวม",
          align: "left",
          sortable: true,
          value: "RubberWeightTotal",
        },
        {
          text: "%",
          align: "left",
          sortable: true,
          value: "RubberPercent",
        },
        {
          text: "ยางแห้ง",
          align: "left",
          sortable: true,
          value: "RubberWeightDry",
        },
        {
          text: "ราคา",
          align: "left",
          sortable: true,
          value: "BillSalesPriceToday",
        },
        {
          text: "รวมเงิน",
          align: "left",
          sortable: true,
          value: "BillSalesTotal",
        },
        {
          text: "จ่าย",
          align: "left",
          sortable: true,
          value: "BillSaleReceive",
        },
        {
          text: "ค้าง",
          align: "left",
          sortable: true,
          value: "BillSaleStale",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.UserId);
    });
  },
  methods: {
    Insert() {
      this.Widen.Array = this.desserts.Array;
      axios.post("/api/v1/Insert/InsertWiden/billsale", this.Widen).then((res) => {
        if (res.status == 200) {
          this.Prints(res.data)
          this.Select(this.UserId);
        }
      });
    },
    Prints(v){
      axios.get(`/api/v1/FinanceWiden/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
    Select(v) {
      axios
        .get("/api/v1/select/SelectWiden/BillSaleDetail?UserId=" + v)
        .then((res) => {
          if (res.status == 200) {
            this.desserts = res.data;
            this.Widen.WidenTotal = res.data.Sum;
          }
        });
    },
  },
};
</script>
